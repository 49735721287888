@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&family=Playfair+Display&display=swap');

@font-face {
  font-family: "Isa";   /*Can be any text*/
  src: local("Isa"),
    url("./Fonts/Isa.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

/* 16 / 0.035 = 457*/
@media (min-width: 457px) {
  body {
    font-size: 1rem;
  }
}


